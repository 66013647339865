import axios from 'axios'
import i18n from '@/i18n';

const state = {
  options: {},
  serverTotal: 1,
  page: 1,
  selectedEmployee: null,
  shifts: [],
  contracts: [],
  employeesContracts: [],
  employees: [],
  leaves: [],
  variations: [],
  loans: [],
  accounts: [],
  cashs: [],
  rules: [],
  custodies: [],
  payments: [],
  data: [],
  url: '/hr/employee',
  employeesUrl: '/hr/employee/auto-complete',
  employeesContractsUrl: '/hr/employee/contract/auto-complete',
  shiftsUrl: '/hr/shift/auto-complete',
  contractsUrl: '/hr/contract/auto-complete',
  variationsUrl: '/hr/bonus/auto-complete',
  leavesUrl: '/hr/leave/type/auto-complete',
  loansUrl: '/hr/loan/type/auto-complete',
  accountsUrl: '/accounting/account/auto-complete',
  rulesUrl: '/hr/rule',
  accountStatementUrl: '/accounting/account/statement/',
  custodiesUrl: '/hr/employee/custody',
  loading: false,
  headers: [
    {text: 'serial number', value: 'serial_number'},
    {text: 'hr.employee.first_name', value: 'name'},
    {text: 'hr.employee.last_name', value: 'last_name'},
    {text: 'hr.employee.contract', value: 'contract_name'},
    {text: 'hr.employee.shift', value: 'shift_name'},
    {text: 'hr.employee.email', value: 'email'},
    {text: 'hr.employee.emp_date', value: 'employment_date'},
  ],
  loanHeaders: [
    {text: i18n.t('hr.loan.type'), value: 'name'},
    {text: i18n.t('value'), value: 'value'},
    {text: i18n.t('hr.employee.number-of-payments'), value: 'payments_number'},
    {text: i18n.t('hr.loan.trans-value'), value: 'payment'},
    {text: i18n.t('hr.loan.loan-value'), value: 'account.name'},
    {text: i18n.t('hr.time.date'), value: 'given_date'},
    {text: i18n.t('hr.vacation.note'), value: 'note'},
    {text: i18n.t('hr.employee.transactions-payed'), value: 'payments'},
    {text: i18n.t('hr.employee.date-of-payment'), value: 'stop_date'},
  ],
  checkHeaders: [
    {text: i18n.t('hr.employee.check.loan-taker'), value: 'debit'},
    {text: i18n.t('hr.employee.check.loan-owner'), value: 'credit'},
    {text: i18n.t('hr.employee.check.account'), value: 'status'},
    {text: i18n.t('hr.employee.check.description'), value: 'desc'},
    {text: i18n.t('hr.employee.check.cost-center'), value: 'cost_center.name'},
    {text: i18n.t('hr.employee.check.date'), value: 'date'},

  ]
}

const getters = {
  payments(state) {
    return state.payments.map(payment => ({...payment, debit: payment.type === 'debit' ? payment.amount : '', credit: payment.type === 'credit' ? payment.amount : ''}))
  },
  data(state) {
    return state.data
  },
  shifts(state) {
    return state.shifts
  },
  contracts(state) {
    return state.contracts
  },
  employees(state) {
    return state.employees
  },
  employeesContracts(state) {
    return state.employeesContracts
  },
  leaves(state) {
    return state.leaves
  },
  variations(state) {
    return state.variations
  },
  loans(state) {
    return state.loans
  },
  accounts(state) {
    return state.accounts
  },
  cashs(state) {
    return state.cashs
  },
  rules(state) {
    return state.rules
  },
  custodies(state) {
    return state.custodies
  },
  headers(state) {
    return [...state.headers, {text: 'settings', value: 'actions'}]
  },
  loanHeaders(state) {
    return [...state.loanHeaders]
  },
  checkHeaders(state) {
    return [...state.checkHeaders]
  }
}
const mutations = {}
const actions = {
  setEmployee({}, employee) {
    state.selectedEmployee = employee
  },
  async fetch({commit}, queryParams) {
    try {
      state.loading = true
      let params = {
        page: state.options?.page ?? 1,
        perPage: state.options?.itemsPerPage ?? 10,
        per_page: state.options?.itemsPerPage ?? 10,
        ...queryParams,
      };
      const response = await axios.get(`${state.url}`, {
        params,
      });
      state.data = response.data.data
      state.serverTotal = response.data.meta.total
      state.page = response.data.meta.current_page
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async fetchEmployee({commit}, queryParams) {
    try {
      state.loading = true
      
      const response = await axios.get(`${state.url}/${queryParams}`);
      let da = response.data.data.data
      return da;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  
  async update({commit, dispatch}, form) {
    try {   
      state.loading = true
      const response = await axios.put(`${state.url}/${form.id}`, form);
      if(response.status==200){
        return response;
      }
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async fetchShifts({commit}, queryParams = '') {
    state.shifts = (await axios.get(`${state.shiftsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchContracts({commit}, queryParams = '') {
    state.contracts = (await axios.get(`${state.contractsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchEmployees({commit}, queryParams = '') {
    state.employees = (await axios.get(`${state.employeesUrl}`, {
        params: {
          name: queryParams
        },
      })).data.data;
  },
  async fetchEmployeesContracts({commit}, queryParams = '') {
    state.employeesContracts = (await axios.get(`${state.employeesContractsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchLeaves({commit}, queryParams = '') {
    state.leaves = (await axios.get(`${state.leavesUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchVariations({commit}, queryParams = '') {
    state.variations = (await axios.get(`${state.variationsUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchLoans({commit}, queryParams = '') {
    state.loans = (await axios.get(`${state.loansUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchAccounts({commit}, queryParams = '') {
    state.accounts = (await axios.get(`${state.accountsUrl}`, {
      params: {
        query: queryParams
      },
    })).data.data;
    return state.accounts
  },
  async fetchCashs({commit}, queryParams = '') {
    if(queryParams != '')
   {
    state.cashs = (await axios.get(`${state.accountsUrl}`, {
      params: {
        query: queryParams,
        cash: 1,
      },
    })).data.data;
   }
  },
  async fetchAccountStatement({commit}, id) {
    state.payments = (await axios.get(`${state.accountStatementUrl}/${id}`)).data.data;
  },
  async fetchRules({commit}, queryParams = '') {
    state.rules = (await axios.get(`${state.rulesUrl}`, {
      params: {
        name: queryParams
      },
    })).data.data;
  },
  async fetchCustodies({commit}, queryParams) {
    state.custodies = (await axios.get(`${state.custodiesUrl}`, {
      params: {
        ...queryParams
      },
    })).data.employee_custodies;
  },
  async store({commit, dispatch}, data) {
    // loading
    try {
      state.loading = true
      // let formemployee = da;
      // for (let key in data) {
      //     if (data[key]) {
      //         formemployee.append(`${key}`, data[key])
      //     }
      // }
      // let formemployee_contract = new FormData();
      // for (let key in data['employee_contract']) {
      //     if (data['employee_contract'][key]) {
      //       formemployee_contract.append(`${key}`, data['employee_contract'][key])
      //     }
      // }
      // let form = new FormData();
      // form.append("employee",formemployee);
      // form.append("employee_contract",formemployee_contract)
      const response = await axios.post(`${state.url}`, data);
      
      if(response.status==200){
        return response;
      }
      dispatch('fetch')
     
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }    // call then close the loading
  },
  async deleteEmployeeContract({commit, dispatch}, id) {
    // loading
      const response = await axios.delete(`${state.url}/contract/${id}`);
      dispatch('fetch')
  },
  async saveEmployeeContract({commit, dispatch}, data) {
    // loading
    try {
      const response = await axios.post(`${state.url}/contract`, data);
      return response;
    } catch (error) {
      
    }
    
      // dispatch('fetch')
  },
  async deleteEmployee({commit, dispatch}, id) {
    // loading
      const response = await axios.delete(`${state.url}/${id}`);
      dispatch('fetch')
  },
  async deleteEmployeeRule({commit, dispatch}, data) {
    // loading
      const response = await axios.delete(`${state.url}/contract/rule`, {
        params: {
          ...data
        }
      });
      dispatch('fetch')
  },
  async saveEmployeeRule({commit, dispatch}, data) {
    try {
      const response = await axios.post(`${state.url}/contract/rule`, data);
    return response;
    } catch (error) {
      
    }
  },
  async deleteEmployeeCustody({commit, dispatch}, id) {
    // loading
    const response = await axios.delete(`${state.url}/custody/${id}`);
    dispatch('fetchCustodies')
  },
  async saveEmployeeCustody({commit, dispatch}, data) {
   try {
    const response = await axios.post(`${state.url}/custody`, data);
    return response;
   } catch (error) {
    
   }
    
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

import axios from 'axios'
import i18n from '@/i18n'
import moment from 'moment'
import {confirmDialog} from "@/helpers/sweetalert";
import { saveAs } from 'file-saver';

const state = {
  options: {},
  serverTotal: 1,
  data: [
  ],
  url: '/hr/work-day',
  forMonthUrl: '/hr/work-day/range',
  loading: false,
  // headers: [
  //   {text: i18n.t('hr.time.date'), value: 'date'},
  //   {text: i18n.t('hr.time.emp-name'), value: 'employee'},
  //   {text: i18n.t('hr.time.shift'), value: 'shift_name'},
  //   {text: i18n.t('hr.time.date-in'), value: 'in_hour'},
  //   {text: i18n.t('hr.time.date-out'), value: 'out_hour'},
  //   {text: i18n.t('hr.time.hours'), value: 'hours'},
  // ],
}
const getters = {
  data(state) {
    return state.data;
  },
  // headers(state) {
  //   return [...state.headers, {text: '', value: 'actions'}]
  // }
}
const mutations = {}
const actions = {
  async fetch({commit, state}, queryParams) {
    state.loading = true
    try {
      let params = {
        employee_contract_id: queryParams.employee_contract_id,
        to: queryParams.to,
        from: queryParams.from,
        page: state.options?.page ?? 1,
        perPage: state.options?.itemsPerPage ?? 10,
        per_page: state.options?.itemsPerPage ?? 10,
      };
      // if (queryParams.paginationValue) {
      //   params.page = queryParams.paginationValue;
      // }
      // if (!queryParams.nameSearch) {
      //   delete params.name;
      // }
      
      const response = await axios.get(`${state.url}`, {
        params,
      });
      // state.tableMeta = {
      //   total: response.data.meta.total,
      //   page: response.data.meta.current_page,
      // }
      state.data = response.data.work_days
      state.serverTotal = response.data.meta.total
      state.options.page = response.data.meta.current_page
    } catch (err) {
      console.log('err', err);
    }
      state.loading = false

  },
  async update({commit, state, dispatch}, data) {
    try {
      state.loading = true
      await axios.put(`${state.url}/${data.id}`, data);
      dispatch('fetch')
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async addNewTime({commit, state, dispatch}, data) {
    try {
      state.loading = true
      let d = {       
          date : data.date,
          note : data.note,
          employee_contract_id : data.employee,
          out_hour : data.out_hour,
          in_hour : data.in_hour,
      }
      await axios.post(`${state.url}`, d);
      dispatch('fetch')

    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async fillForMonth({commit, state, dispatch}, data) {
    try {
      state.loading = true
      await axios.post(`${state.forMonthUrl}`, data);
        this.$Notifications(
            "تمت توليد الدوام بنجاح",
            { rtl: true, timeout: 2500 },
            "success"
          );
      dispatch('fetch')
    
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async export({commit, state, dispatch}, queryParams) {
    try {
      state.loading = true
      const excel = await axios.get(`${state.url}/export`, {
        params: {
          ...queryParams,
          start_date: queryParams.from,
          end_date: queryParams.to,
        },
        responseType: 'blob'
      });
      saveAs(excel.data)
      dispatch('fetch')

    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async delete({commit, state, dispatch}, id) {
    try {
      state.loading = true
      if (await confirmDialog()) {
        await axios.delete(`${state.url}/${id}`);
        dispatch('fetch')
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  }
}

function calculateTotalHours(data) {
  const inHour = moment(data.in_hour, 'HH:mm:ss');
  const outHour = moment(data.out_hour, 'HH:mm:ss');
  const diffInMilliseconds = outHour.diff(inHour);
  return moment.duration(diffInMilliseconds).asHours();
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import { i18n } from "./i18n";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from "./helpers/axios-helper";
import "element-ui/lib/theme-chalk/index.css";
import UUID from "vue-uuid";
import vueDebounce from "vue-debounce";
import Notifications from "./helpers/toast-notifications";
import baseTable from "./components/base/baseTable";
import spinner from "./components/base/spinner";
import VueGeolocation from "vue-browser-geolocation";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/spell_checker.min";
import "froala-editor/js/third_party/image_tui.min";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "./globalCss/global.css";
import VueFroala from "vue-froala-wysiwyg";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import * as VueGoogleMaps from "vue2-google-maps" 
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB94UYeQCRDrnG6b2CL_RLrmJIJzYEUlbQ",
  }
});
Vue.use(VueQuillEditor );
Vue.use(VueGeolocation)
import GlobalEvents from "vue-global-events";
Vue.component("GlobalEvents", GlobalEvents);
Vue.mixin({
  data() {
    return {
      displayDeleteIcon: false
    };
  },
  methods: {
    returnAbility(ability) {
      if (this.$store.state.userData.abilities.includes("*")) {
        return true;
      } else {
        return this.$store.state.userData.abilities.includes(ability);
      }
    }
  }
});
Vue.prototype.$Notifications = Notifications;
Vue.component("app-base-table", baseTable);
Vue.component("app-spinner", spinner);
Vue.use(VueFroala);
Vue.use(UUID);
Vue.use(axios);
Vue.use(Toast);
Vue.use(vueDebounce, {
  defaultTime: "700ms"
});
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.isPublic);
  if (!isPublic && !localStorage.getItem("access_token")) {
    if (to.path === "/Login") next(); else  next({ path: '/Login' });
    }
   else {
    next();
  }
});
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");

document.title = store.state.schSetting.SCHOOL_NAME;
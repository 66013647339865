import { onUnmounted, onMounted, ref } from 'vue';

export function useGeolocation() {
    const coords = ref({ latitude: null, longitude: null });
    const isSupported = 'navigator' in window && 'geolocation' in navigator;
    let watcher = null;

    const updatePosition = (position) => {
        coords.value = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        };
    };

    const handleError = (error) => {
        console.warn("Geolocation error:", error.message);
        // coords.value = { latitude: 33.5138, longitude: 36.2765 }; // Example coordinates for Damascus
        coords.value = { latitude: 33.312805, longitude: 44.361488 }; 
    };

    onMounted(() => {
        if (isSupported) {
            navigator.geolocation.getCurrentPosition(
                updatePosition,
                handleError
            );

            watcher = navigator.geolocation.watchPosition(updatePosition, handleError);
        } else {
            console.warn("Geolocation is not supported by this browser.");
        }
    });

    onUnmounted(() => {
        if (watcher) navigator.geolocation.clearWatch(watcher);
    });

    return { coords, isSupported };
}

import axios from 'axios'
import i18n from '@/i18n';
// import {confirmDialog} from "@/helpers/sweetalert";

const state = {
  options: {},
  serverTotal: 1,
  page: 1,
  data: [],
  url: '/hr/calculate',
  loading: false,
  calculation: null,
  id: null,
}
const getters = {
  data(state) {
    return state.data
  },
  isThereCalculation(state) {
    return state.calculation
  },
  idCalculation(state) {
    return state.id;
  },
  calculationData(state) {
    return state.calculation?.calculate ?? []
  },
  headers(state) {
    return [...state.headers, {text: '', value: 'actions'}]
  },
  calculationHeaders(state) {
    return [...state.calculationHeaders]
  }
}
const mutations = {}
const actions = {
  deleteOldCalculation(){
    state.calculation = null;
  },
  async fetch({commit}, queryParams) {
    try {
      state.loading = true
      // const filterTemp = {};
      //   Object.keys(queryParams).forEach((key) => {
      //     if (queryParams[key]) filterTemp[key] = queryParams[key];
      //   });
      // let params = { ...filterTemp };
      const response = await axios.get(`${state.url}/group`
        // , {params,}
      );
      state.data = response.data.data;
      state.serverTotal = response.data.meta.total;
      state.page = response.data.meta.current_page;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async generate({commit, dispatch}, form) {
    try {
      state.loading = true
      let params = {};
      const response = await axios.post(`${state.url}/start`, form);
      state.calculation = response.data.data.calculate;
      state.id = response.data.data.id;
      // dispatch('fetch')
      return response;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  setCalculation({commit, dispatch}, form) {
    state.calculation = form
  },
  async sendMail({commit, dispatch}, id) {
    try {
      state.loading = true
      const response = await axios.get(`${state.url}/mail/${id}`);
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  // async delete({commit, state, dispatch}, id) {
  //   try {
  //     state.loading = true
  //     if (await confirmDialog()) {
  //       await axios.delete(`${state.url}/${id}`);
  //       dispatch('fetch')
  //     }
  //   } catch (err) {
  //     console.log('err', err);
  //   } finally {
  //     state.loading = false
  //   }
  // }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

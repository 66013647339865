<template>
  <div>
    
    <v-autocomplete :label="label"  dense :dark="$store.state.isDarkMode" :rounded="rounded" item-text="name"
      item-value="id" full-width hide-details :solo="solo" :outlined="outlined" :flat="flat" :items="itemsVal"
      :clearable="clearable" :value="value" :return-object="returnObject" :loading="loading" :type="type"
      :filter="filter" v-debounce="autoCompleteFn" @keydown.enter="$emit('keydown')"  @change="handleChange" 
      @input="$emit('input', $event)" :rules="rules" :disabled="disabled"></v-autocomplete>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    rules: {
      
    },
    label: {
      type: String,
    },
    value: {
      type: [Object, String, Number],
    },
    endPoint: {
      type: String,
    },
    itemsList: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    cash: {
      type: Number,
      default: null,
    },
    filter: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemsVal: [],
      loading: false,
      selecteditem: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v && typeof v == "object") {
          this.itemsVal = [{ ...v }];
        }
      },
    },
  },
  methods: {
    handleChange(selectedItem) {
      this.$emit('change', selectedItem);
    },
    async autoCompleteFn(value) {
      console.log(this.endPoint)
      try {
        if (!value) return;
        this.loading = true;
        let params = {
          name: value,
          query: value,
          cash: this.cash,
          account_id: this.filter,
          type: this.type,
        };
        if (this.endPoint == '/student/auto-complete') {
          if (this.type == 'regRecord') {}
          else if (this.type == 'parent_order') {
            params = {
                name: value,
                query: value,
                type: "parent_order",
            };
          }
          else {
            params = {
              name: value,
              query: value,
              type: "full_name",
            };
          }
        }
        //   if(this.itemsList != []) {
        //   this.itemsVal = this.itemsList;
        //  }
        // else{
        const searchResult = await axios.get(this.endPoint, {
          params: params,
        });
        if (this.endPoint == '/user/auto-complete') this.itemsVal = searchResult.data.users;
        else if (this.endPoint == '/test') this.itemsVal = searchResult.data.tests;
        else if (this.endPoint == '/edu-class') this.itemsVal = searchResult.data.classes;
        else if (this.endPoint == '/grade') this.itemsVal = searchResult.data.grades;
        else if (this.endPoint == '/grade-type') this.itemsVal = searchResult.data.grade_types;
        else if (this.endPoint == '/educational-year') this.itemsVal = searchResult.data.educational_years;
        else if (this.endPoint == '/terms') this.itemsVal = searchResult.data.terms;
        else if (this.endPoint == '/subject') this.itemsVal = searchResult.data.subjects;
        else if (this.endPoint == '/meal') this.itemsVal = searchResult.data.meals;
        else if (this.endPoint == '/route') this.itemsVal = searchResult.data.routes;
        else if (this.endPoint == '/teacher') this.itemsVal = searchResult.data.teachers;
        else if (this.endPoint == '/hr/rule') this.itemsVal = searchResult.data.rules;
        else if (this.endPoint == '/hr/custody') this.itemsVal = searchResult.data.custodies;
        else this.itemsVal = searchResult.data.data;
        //  }
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
